/* <link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap" rel="stylesheet"> */
* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

/* "proxy": "http://localhost:80", */
/* "proxy": "https://cityluxedxb.globaltechsoft.com", */


body {

    
    overflow-x: hidden;
    padding: 0px;
    margin: 0px;
    /* color: #5c727d, */
    color: #5c727d !important;
    background-color: #fff !important;
    font-family: 'Nunito Sans', sans-serif !important;

}


/* css for home video  */
.back_video {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: -2;

}

@media (min-aspect-ratio: 16/9) {
    .back_video {
        width: 100%;
        height: auto;
    }
}

@media (max-aspect-ratio: 16/9) {
    .back_video {
        width: auto;
        height: 100%;
    }
}


/* css for home text slider with text animation */
.text_slide {
    position: absolute;
    right: 0;
    bottom: 0;
    /* border: 5px solid red !important; */
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: #0000003d;
}



.CardImage {
    height: 35vh;
}

@media only screen and (max-width: 900px) {
    .CardImage {
        height: 50vh;
    }
}

@media only screen and (max-width: 500px) {
    .CardImage {
        height: 35vh;
    }
}

.carousel_section {

    width: 100vw;
    height: 100vh;

}




.carousel-indicators {
    /* border: 1px solid red; */
    position: absolute !important;
    top: 40% !important;
    right: -14% !important;
    left: 70vw !important;
    bottom: 30% !important;
    z-index: 5 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-end !important;

}

.carousel-indicators button {
    width: 10px !important;
    height: 10px !important;
    border: 2px solid #fff !important;
    background-color: transparent !important;
    margin-top: 10px !important;
    transition: 0.5s ease !important;
}

.carousel-indicators button:hover {

    background-color: #fff !important;

}

.carousel-indicators .active {
    /* opacity: 1; */
    background-color: #fff !important;
    /* background-color: #ffffff59 !important; */
}

/* @media only screen and (min-width: 800px) { */
.item {
    width: 100vw;
    height: 100vh;
    padding: 30vh 14vw;
    /* background-color: #33333321; */
}


@media only screen and (max-width: 600px) {
    .item {
        padding: 35vh 5vw;


    }

   
}

.TeamMemberPhoto {
    padding: 10px 10px 0px 10px;
    /* margin-top: 5px; */
    width: 100%;
    height: 100%;

    transition-property: transform opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.TeamMemberPhoto:hover {
    opacity: 0.8;
    transform: scale(1.1);
}





.formAdvanceSearch {
    padding: 0px !important;
}



.formAdvanceSearch .autocompliteInput .MuiFormControl-root .MuiInputBase-root fieldset {
    border: 1px solid #5c727d !important;
    border-radius: 0px !important;
}

.newsletter fieldset {
    border: 1px solid #5c727d !important;
    border-radius: 0px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-radius: 0px !important;
}


.propertyPagination ul li button {
    border-radius: 0px !important;
    color: #d7c3a8 !important;
    border: 1px solid #d7c3a8 !important;
}


#combo-box-demo {
    font-weight: 600 !important;
    padding: 5px 0px 5px 40px !important;
    font-size: 13px !important;
    font-family: 'Nunito Sans', sans-serif !important;
    color: #5c727d !important;
   
}



.search_property::before {
    font: var(--fa-font-solid);
    content: "\f002";
    position: absolute;
    left: 0px;
    top: 12px;
    padding: 0px 15px;
}

.search_categories::before {
    font: var(--fa-font-solid);
    content: "\f64f";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;

}

.search_type::before {
    font: var(--fa-font-solid);
    content: "\f03a";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;
}

.search_min_bed::before {
    font: var(--fa-font-solid);
    content: "\f236";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;
}

.search_max_bed::before {
    font: var(--fa-font-solid);
    content: "\f236";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;
}

.search_min_price::before {
    font: var(--fa-font-solid);
    content: "\f0d6";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;
}

.search_max_price::before {
    font: var(--fa-font-solid);
    content: "\f0d6";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;
}

.search_min_area::before {
    font: var(--fa-font-solid);
    content: "\f1fe";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;
}

.search_max_area::before {
    font: var(--fa-font-solid);
    content: "\f1fe";
    position: absolute;
    top: 12px;
    left: 0px;
    padding: 0px 15px;
}



/* single page property details  */


/* single page caraousel for zoom and image priview  */
.wrapper {
   
    overflow: hidden !important;
}

.image-gallery-slides {
    background: #333 !important;
    width: 100%;
    position: relative;
    height: 425px !important;
    /* height: auto !important; */
}

@media only screen and (max-width: 700px) {
    .image-gallery-slides {
        height: auto !important;

    }
}


.image-gallery-thumbnails {
    /* overflow: hidden; */
    padding: 0px 0px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
    border: 2px solid #fff !important;
}

.image-gallery-bullets {
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 auto;
    position: absolute;
    width: 100% !important;
    z-index: 4;
    background-color: #333;
    padding: 15px 0px;
}

.image-gallery-bullets .image-gallery-bullets-container {
    text-align: right !important;
    padding: 0px 30px !important;
}

.image-gallery-bullet {
    /* border: 0.5px solid #fff !important;
    border-radius: 0px !important;
    box-shadow: none !important; */
    padding: 4px !important;

}

.image-gallery-bullets .image-gallery-bullet {
    /* appearance: none; */
    background-color: #8595a9 !important;
    border: 0px !important;
    border-radius: 50%;
    box-shadow: none !important;
    cursor: pointer;
    /* display: inline-block; */
    margin: 0 5px;
    /* outline: none; */
    padding: 5px;
    transition: 'none' !important;
}

.image-gallery-bullets .image-gallery-bullet:hover {
    /* background-color: #8595a9;  */
    /* border: 0px !important; */
}

.image-gallery-bullets .active {
    background-color: #fff !important;

}

.image-gallery-thumbnails-wrapper {
  
    position: absolute !important;
    width: 100%;
    left: 0px;
    bottom: -42px;
    background-color: black !important;
    z-index: 3;
    transition: all .8s ease-in-out;
    /* -webkit-transition: all .8s ease-in-out; */
}

.image-gallery-thumnails-wrapper-show-and-hide-thumnail {
    /* top: -115px; */
    bottom: 38px;
    /* z-index: 10; */
    transition: all .8s ease-in-out;
}


.image-gallery-thumbnail {
    border: 0px !important;
    width: 150px !important;
    background: #333 !important;
    padding: 0px 2px 0px 0px !important;
    opacity: 1 !important;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    background: black !important;
    padding-top: 1px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    outline: none !important;
    border: 0px !important;
}

.image-gallery-thumbnail:hover {
    outline: none !important;
    border: 0px !important;
    opacity: 0.8 !important;
}



.addThumnail {
    
    cursor: pointer;
    position: absolute;
    bottom: 17px;
    right: 5px;
}



.addThumnail::after {
    font-family: "Font Awesome 5 Free";
    padding: 0px 0px 0px 0px;
    color: #fff;
    content: "\f106";
    margin: 0px;
    position: absolute;
    bottom: 15;
    right: 5px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.removeThumnail::after {
    content: "\f107";
}



.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 20px !important;
    width: 20px !important;

}

.image-gallery-fullscreen-button {
    right: 0;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    bottom: 0;
    padding: 20px;
}

.image-gallery-icon:hover {
    color: #e1d3bc !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 40px !important;
    width: 40px !important
}


/* css for admin adminControl panel  */

.activeManu {

    /* color: blue !important; */
    /* background-color: red !important; */
    background-color: #d7c3a8 !important;
    /* background-color: rgba(0, 0, 0, 0.254) !important; */
}

/* css for sorting component  */


.MuiPaper-root .MuiFormControl-root .MuiInputBase-root fieldset {
    border-style: none !important;
}

.MuiBox-root .MuiFormControl-root .MuiInputBase-root fieldset {
    border-style: none !important;
}



.MuiGrid-root .MuiBox-root .MuiBox-root .MuiFormControl-root .MuiInputBase-root svg {
    color: #fff !important;
}

/* css-bpeome-MuiSvgIcon-root-MuiSelect-icon */

/* .slick-next:before {
    color: #d7c3a8 !important;
}

.slick-prev:before {
    color: #d7c3a8 !important;
} */

/* .propertyListAnimation::before {
    content: "";
    animation-name: animate;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;

} */

/* style for property list page contact form select elements  */
.ProperytList fieldset {
    border-width: 0px !important;
    border-color: none !important;
}

.css-ProperytList:hover .ProperytList fieldset {
    border-width: 0px !important;
    border-color: none !important;
}

/* css for property text slider */

.PropertyListTextslider {
    height: 85px;
    overflow: hidden;
    /* border: 1px solid red; */
}

.PropertyListTextslide {
    height: 100%;
    animation: slideUp 6s ease-in infinite;
}

@keyframes slideUp {
    0% {
        transform: translateY(65px);
    }

    100% {
        transform: translateY(-170px);
    }
}

@media only screen and (max-width: 900px) {
    .PropertyListTextslider {
        /* overflow: visible; */
        height: 40px;
        /* border: 1px solid red; */
    }
    .PropertyListTextslide {
        height: 100%;
        animation: slideUp 4s ease-in infinite;
    }
    
    @keyframes slideUp {
        0% {
            transform: translateY(40px);
        }
    
        100% {
            transform: translateY(-80px);
        }
    }
}

/* style for Advance search section */

.css-heliqj-MuiPaper-root-MuiCard-root{
position:initial !important ;
}


/* style for unnessesery box within "SORT BY" section */

.css-igs3ac{
    border: none !important;
}

/* style for twitter icon */

.forHover:hover{
    fill: #D7C3A8;
    transition: fill 0.3s; /* Smooth transition on hover */
    color: red;

}

/* Blogs title style */
.no-underline {
    text-decoration: none;
  }

  .react-tel-input {
    margin-bottom: 10px !important;
  }
