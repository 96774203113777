
.main_section_type_properties{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    /* border: 1px solid red; */
     padding: 0px 15px;
}

.table_box {
    /* border: 1px solid red; */
    /* background-color: aqua; */
    height: 60vh;
    overflow-y: scroll;
    border-bottom: 5px solid rgba(149, 157, 165, 0.2);
}

.table {

    
}

.table .table_head {
    position: sticky;
    z-index: 1;
    width: 100%;
    top: 0px;
    left: 0px;
}

.table .table_head th {
    /* border: 1px solid red; */
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: 1rem;
    padding: 10px 10px;
    background-color: rgb(107, 105, 105);
    border: 1px solid rgba(29, 27, 27, 0.685);
    color: #fff;
}

.table .table_body {}

.table .table_body td {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 1rem;
    padding: 5px 2px;
    border: 1px solid rgba(119, 105, 105, 0.616);
}

.table .table_body .icon {
    cursor: pointer;
}