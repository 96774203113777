 /* #app {
   height: 80%;
   border: 2px solid green;
 } */

 /* body {
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

 .swiper {
   width: 1150px;
   height: 100%;
   /* border: 1px solid red; */
   position: inherit !important;
   /* pointer-events: none !important */
 }

 @media only screen and (max-width: 500px) {
   .swiper-wrapper {
     /* z-index: 1 !important; */
     /* background-color: rgba(0, 0, 0, 0.267) !important; */
     /* pointer-events: none !important */
   }
 }




 @media only screen and (max-width: 1200px) {
   .swiper {
     width: 95%;
   }
 }

 /* @media only screen and (max-width: 1200px) {
   .swiper {
     width: 1100px;
   }
 }

 @media only screen and (max-width: 1100px) {
   .swiper {
     width: 1000px;
   }
 }

 @media only screen and (max-width: 1000px) {
   .swiper {
     width: 900px;
   }
 }

 @media only screen and (max-width: 950px) {
   .swiper {
     width: 850px;
   }
 } */

 @media only screen and (max-width: 900px) {
   .swiper {
     width: 90%;
   }
 }

 .swiper-slide {
   background: transparent;
   /* background: rgba(0, 0, 0, 0.078); */
   display: flex;
   flex-direction: column;
   /* justify-content: center; */
   align-items: flex-start;
   padding: 10vh 0px;
 }


 @media only screen and (max-width: 1100px) {
   .swiper-slide {
     padding: 100px 0px;
   }
 }

 @media only screen and (max-width: 900px) {
   .swiper-slide {
     padding: 25vh 0px;
   }
 }

 @media only screen and (max-width: 600px) {
   .swiper-slide {
     padding: 10px 0px;
   }
 }

 /* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

 /* custome css for indicators */

 .css-x15wq9 {
   position: absolute !important;
   color: wheat !important;
   width: 50px !important;
   height: 50px !important;
 }

 .swiper-pagination-bullet {
   border-radius: 0px !important;
   width: 10px !important;
   height: 10px !important;
   border: 2px solid #fff !important;
   background-color: transparent !important;
   margin-top: 10px !important;
   transition: 0.5s ease !important;
 }

 .swiper-pagination-bullet-active {
   background-color: #fff !important;
 }

 .swiper-pagination-bullet:hover {
   background-color: #fff !important;
   /* color: gray; */
 }

.tabsStyle{
  display: flex !important;
  justify-content: space-around !important;
}